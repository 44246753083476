<template>
  <v-container>
    <h1>Acknowledgements</h1>
    <p>This page currently has no acknowledgments. A list will be provided below if some are added.</p>
    <v-btn color="primary" :to="{name: 'Index'}">Return home</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "Acknowledgments",
  metaInfo: {
    title: "Acknowledgments",
    "http-equiv": "Content-Security-Policy",
    content: "upgrade-insecure-requests",
  },
}
</script>

<style scoped>

</style>